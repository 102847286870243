import React from 'react';
import './main.css';
import './normalize.css'

export default function App() {
    return (
        <div>
            <section id="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <img width="200" src="/obyacademy.png"/>
                            <hr className="my-4" style={{borderColor: "transparent"}}/>
                            <h1 className="display-4" style={{fontWeight: 500}}>
                                Customised programming courses for your employees and students to stay ahead of
                                disruption.
                            </h1>
                            <hr className="my-4" style={{borderColor: "transparent"}}/>
                            {/*<div className="d-flex flex-wrap">
                                <a href="https://students.parallebs.com/register"
                                   className="common-Button button-1">
                                    Student Registration
                                </a>
                            </div>*/}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <img className="img-fluid"
                                 src="https://storage.googleapis.com/parallebs.com/img/testintro.svg"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="aboutus">
                <div className="container">
                    <div className="d-flex flex-wrap">
                        <div
                            className="intro-company col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                            <div>
                                <h1>
                                    Our Customer Focus
                                </h1>
                                <hr className="my-4"/>
                                <div className="display-4">
                                    We plan programming courses and deliver relevant learning objectives to suit
                                    your
                                    organisation's schedule and
                                    agendas.
                                </div>
                                <hr className="my-4"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12 intro-example d-flex align-items-center">
                            <div className="d-flex flex-wrap ml">
                                <a className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div style={{padding: '20px 10px'}}>
                                        <figure>
                                            <img className="img-fluid"
                                                 src="https://storage.googleapis.com/parallebs.com/img/education.svg"/>
                                        </figure>
                                        <div className="course-info">
                                            <h2>Education & Institutions</h2>
                                            <hr className="my-4"/>
                                            <h3>Accelerated mass courses that fit your institution's busy
                                                schedules.</h3>
                                            <hr className="my-4"/>
                                        </div>
                                    </div>
                                </a>
                                <a className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div style={{padding: '20px 10px'}}>
                                        <figure>
                                            <img className="img-fluid"
                                                 src="https://storage.googleapis.com/parallebs.com/img/business-con.svg"/>
                                        </figure>
                                        <div className="course-info">
                                            <h2>Enterprises</h2>
                                            <hr className="my-4"/>
                                            <h3>Customised courses that ends with a hackathon of your company's
                                                business
                                                case</h3>
                                            <hr className="my-4"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="clients">
                <div className="container">
                    <h1>Clients</h1>
                    <div className="row">
                        <div className="col-6 col-md-4 col-lg-4">
                            <img style={{display: 'block', width: '100%', margin: '0 auto'}} src="/ntu.png"/>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4">
                            <img style={{display: 'block', width: '100%', margin: '0 auto'}} src="/img/rsis_logo.png"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="method">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <hr className="my-4" style={{borderColor: "transparent"}}/>
                            <h4>Our teaching philosophy</h4>

                            <h1 className="display-4" style={{fontWeight: 500}}>
                                Begin with an end in mind & passion before mastery
                            </h1>
                            <div style={{fontSize: '16px', marginBottom: '20px'}}>
                                <p>Traditional courses are usually taught chapter by chapter. Students get bored and lost in a very lengthy learning process. </p>
                                <p>Chapter-based learning is irrelevant in today's age of information  By teaching only relevant concepts to achieve an end-product, we keep students engaged throughout our course.</p>
                                <p>We hope to educate and inspire individuals just enough to get started, experiment and break things.</p>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{padding: '20px'}}>
                                    <div style={{background: 'linear-gradient(to right, #BD3F32, #CB356B)', padding: '20px'}}>
                                    <div style={{color: 'white'}}>
                                    <h4>An example generic web development course</h4>
                                    <h1>Introduction to web development</h1>
                                    <ul style={{fontSize: '16px'}}>
                                        <li>HTML Basics</li>
                                        <ul>
                                            <li>Chapter 1: ...</li>
                                            <li>Chapter 2: ...</li>
                                            <li>...</li>
                                        </ul>
                                        <li>CSS Basics</li>
                                        <ul>
                                            <li>Chapter 1: ...</li>
                                            <li>Chapter 2: ...</li>
                                            <li>...</li>
                                        </ul>
                                        <li>Javascript basics</li>
                                        <ul>
                                            <li>Chapter 1: ...</li>
                                            <li>Chapter 2: ...</li>
                                            <li>...</li>
                                        </ul>
                                        <li>SQL basics</li>
                                        <ul>
                                            <li>Chapter 1: ...</li>
                                            <li>Chapter 2: ...</li>
                                            <li>...</li>
                                        </ul>
                                    </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{padding: '20px'}}>
                                <div style={{backgroundColor: '#42175a', color: 'white', padding: '20px'}}>
                                    <div>
                                    <h4>Our courses</h4>
                                    <h1>Create a MVP Airbnb website in 2 days</h1>
                                    <ul style={{fontSize: '16px'}}>
                                        <li>HTML Basics</li>
                                        <li>CSS Basics to style your website like Airbnb</li>
                                        <li>Use Javascript to add, modify and delete house listings on Firebase</li>
                                        <li>Use Javascript to integrate your own payment gatway</li>
                                    </ul>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="courses">
                <div className="container">
                    <h1>Courses we specialise in</h1>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="course-card">
                                <div className="d-flex">
                                    <i className="fab fa-html5"/>
                                    <i className="fab fa-css3-alt"/>
                                    <i className="fab fa-js"/>
                                </div>
                                <h3>HTML, CSS, Javascript</h3>
                                <h2>Basic Web Development</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="course-card">
                                <div className="d-flex">
                                    <i className="fab fa-react"></i>
                                    <i className="fas fa-fire"></i>
                                </div>
                                <h3>React, Firebase</h3>
                                <h2>Full Stack Web Development</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="course-card">
                                <div className="d-flex">
                                    <i className="fab fa-python"></i>
                                    <i className="fas fa-database"></i>
                                </div>
                                <h3>Python & SQL/NoSQL</h3>
                                <h2>Data Analytics</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="course-card">
                                <div className="d-flex">
                                    <i className="fas fa-file-excel"></i>
                                    <i className="fab fa-python"></i>
                                </div>
                                <h3>Excel VBA & Python</h3>
                                <h2>Process Automation</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="gallery">
                <div className="container">
                    <h1>Previous Courses</h1>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/rsis1.jpeg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/ntu4.jpeg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/ntu3.jpeg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/ntu2.jpg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/ntu1.jpeg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(/img/ntu5.jpg)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-students.appspot.com/o/images%2Fntu2.jpg?alt=media&token=c710447c-996a-41d4-810e-67a854b99736)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-students.appspot.com/o/images%2Fntu2.jpg?alt=media&token=c710447c-996a-41d4-810e-67a854b99736)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-students.appspot.com/o/images%2Fntu3.jpg?alt=media&token=678a8ccb-846a-4555-bfd3-cd426f362c6c)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch8.jpg?alt=media&token=280fc8af-1b40-46f1-8887-f5ef31d85dba)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch7.jpg?alt=media&token=743fd17d-2885-4591-ac4e-3bce71714f8f)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch6.jpg?alt=media&token=0e531d70-7330-4253-939b-68851eef054b)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch5.jpg?alt=media&token=945706d4-b812-48b8-8480-16d7f133accd)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch4.jpg?alt=media&token=743b772f-659c-498a-8c22-844300003ebf)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch3.jpg?alt=media&token=b9abb5a4-7062-4585-a0b8-31fa88bd0b35)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch2.jpg?alt=media&token=a14eba1a-be92-4364-aae4-f08ad19f6fe0)"}}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="img-box"
                                 style={{backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/parallebs-home-211016.appspot.com/o/batch1.jpg?alt=media&token=b68618df-63a7-4bd9-ab70-c2c919bf9a67)"}}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}